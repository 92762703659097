import React, { useState, useEffect } from "react";
import Select from "react-select";

const PrescriptionForm = ({ handlePrescriptionSubmit }) => {
  const [tableData, setTableData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    id: "",
    days: "",
    morning: "",
    afternoon: "",
    night: "",
    category: "",
    info: "",
  });
  const [medicineList, setMedicineList] = useState([]);
  const [medicineCategories, setMedicineCategories] = useState([]);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchMedicineCategory();
    fetchMedicineList();
  }, []);

  const fetchMedicineList = async () => {
    try {
      const response = await fetch(
        "https://testingnew.webddocsystems.com/public/api/v1/webdoc/dropdowns/medicine-list"
      );
      const data = await response.json();
      if (data.statusCode === 1) {
        setMedicineList(
          data.payLoad.medicineList.map((medicine) => ({
            value: medicine.Id,
            label: medicine.MedicineName,
          }))
        );
      } else {
        console.error("Failed to fetch medicine list");
      }
    } catch (error) {
      console.error("Error fetching medicine list:", error);
    }
  };

  const fetchMedicineCategory = async () => {
    try {
      const response = await fetch(
        "https://testingnew.webddocsystems.com/public/api/v1/webdoc/dropdowns/medicine-category"
      );
      const data = await response.json();
      if (data.statusCode === 1) {
        setMedicineCategories(data.payLoad);
      } else {
        console.error("Failed to fetch medicine categories");
      }
    } catch (error) {
      console.error("Error fetching medicine categories:", error);
    }
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setSelectedMedicine(selectedOption);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: selectedOption ? selectedOption.label : "",
      id: selectedOption ? selectedOption.value : "",
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  useEffect(() => {
    const { category, morning, afternoon, night, days } = formData;
    if (category && (morning || afternoon || night || days)) {
      const selectedCategory = medicineCategories.find(
        (category) => category.CategoryName === formData.category
      );
      if (selectedCategory) {
        let info = "";
        if (morning) info += `${morning} ${selectedCategory.Morning} `;
        if (afternoon) info += `${afternoon} ${selectedCategory.Day} `;
        if (night) info += `${night} ${selectedCategory.Night} `;
        if (days) info += `${days} ${selectedCategory.NoOfDays}`;
        setFormData((prevFormData) => ({ ...prevFormData, info }));
      }
    }
  }, [
    formData.category,
    formData.morning,
    formData.afternoon,
    formData.night,
    formData.days,
    medicineCategories,
  ]);

  const saveData = () => {
    const prescription = { ...formData };
    const requiredFields = ["name"];
    const newErrors = {};
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field} is required`;
      }
    });
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setTableData([...tableData, prescription]);
    closeModal();
    // Clear form fields and selected medicine after saving
    setFormData({
      name: "",
      id: "",
      days: "",
      morning: "",
      afternoon: "",
      night: "",
      category: "",
      info: "",
    });
    setSelectedMedicine(null);
  };

  const deleteRow = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  useEffect(() => {
    if (tableData.length > 0) {
      handlePrescriptionSubmit(tableData);
    }
  }, [tableData, handlePrescriptionSubmit]);

  return (
    <>
      <div className="row mt-3">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="mb-2 d-flex justify-content-end align-items-center">
                <button className="btn btn-info" onClick={openModal}>
                  Add Data
                </button>
              </div>

              <div className="table-responsive">
                <table
                  className="table border text-nowrap text-md-nowrap table-bordered mb-0"
                  id="data-table"
                >
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Medicine Name</th>
                      <th>No. of Days</th>
                      <th>Morning</th>
                      <th>Afternoon</th>
                      <th>Night</th>
                      <th>Category</th>
                      <th>Additional Instructions</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((prescription, index) => (
                      <tr key={index}>
                        <td>{prescription.id}</td>
                        <td>{medicineList.find(medicine => medicine.value === prescription.id)?.label}</td>
                        <td>{prescription.days}</td>
                        <td>{prescription.morning}</td>
                        <td>{prescription.afternoon}</td>
                        <td>{prescription.night}</td>
                        <td>{prescription.category}</td>
                        <td>{prescription.info}</td>
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => deleteRow(index)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal ${modalVisible ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: modalVisible ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content" style={{ backgroundColor: "azure" }}>
            <div
              className="modal-header"
              style={{ backgroundColor: "#7be3e3" }}
            >
              <h5 className="modal-title">Add Data</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
              ></button>
            </div>
            <div className="modal-body">
              <form id="dataForm">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="name">Medicine Name</label>
                    <Select
                      name="name"
                      id="nameSelect"
                      className={`${errors.name ? "is-invalid" : ""}`}
                      value={selectedMedicine}
                      onChange={handleSelectChange}
                      options={medicineList}
                      placeholder="Select Medicine"
                    />
                    {errors.name && (
                      <div className="invalid-feedback">{errors.name}</div>
                    )}
                    {formData.name === "999999999" && (
                      <input
                        name="otherMedicineName"
                        id="otherMedicineName"
                        className="form-control"
                        placeholder="Enter Medicine name here please"
                        type="text"
                        value={formData.otherMedicineName}
                        onChange={handleChange}
                      />
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="category">Category</label>
                    <select
                      name="category"
                      className="form-control"
                      required=""
                      value={formData.category}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {medicineCategories.map((category) => (
                        <option key={category.id} value={category.CategoryName}>
                          {category.CategoryName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group col-md-4">
                    <label htmlFor="morning">Morning</label>
                    <input
                      type="text"
                      name="morning"
                      className="form-control"
                      id="morning"
                      value={formData.morning}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="afternoon">Afternoon</label>
                    <input
                      type="text"
                      name="afternoon"
                      className="form-control"
                      id="afternoon"
                      value={formData.afternoon}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="night">Night</label>
                    <input
                      type="text"
                      name="night"
                      className="form-control"
                      id="night"
                      value={formData.night}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group col-md-12">
                    <label htmlFor="days">No. of Days</label>
                    <input
                      type="text"
                      name="days"
                      className="form-control"
                      id="days"
                      value={formData.days}
                      onChange={handleChange}
                      required=""
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="info">Additional Information</label>
                    <textarea
                      name="info"
                      className="form-control"
                      id="info"
                      value={formData.info}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Close
              </button>
              <button type="button" className="btn btn-info" onClick={saveData}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrescriptionForm;
