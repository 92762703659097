import React, { useEffect, useRef, useState, useCallback } from "react";
import { ref, update, get } from "firebase/database";
import { database } from "../firebase/firebaseConfig";
import AgoraRTC from "agora-rtc-sdk-ng";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VirtualBackgroundExtension, {
  IVirtualBackgroundProcessor,
} from "agora-extension-virtual-background";
import {
  faPhone,
  faMicrophone,
  faMicrophoneSlash,
  faVideoSlash,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import "./incoming.css";
import $ from "jquery";
import Select from "react-select";
import Swal from "sweetalert2";
import PatientDetails from "./patient-detail";
import PrescriptionForm from "./prescriptions-form";
import AfnPatientDetails from "./afn-patient-detail";
import AfnPrescriptionForm from "./afn-prescriptions-form";

const Incoming = () => {
  const [testsList, setTestsList] = useState([]);
  const [errors, setErrors] = useState({});
  const [patientId, setPatientId] = useState("");
  const [patientDetail, setPatientDetail] = useState("");

  // Function to receive patient ID
  const handlePatientId = (id) => {
    setPatientId(id);
  };
  const handlePatientDetail = (detail) => {
    setPatientDetail(detail);
  };
  // ======= Video Calling Code Start =============
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const localAudioRef = useRef(null);
  // const [token, setToken] = useState(null);
  const [joined, setJoined] = useState(false);
  const [client, setClient] = useState(null);
  const [audioMuted, setAudioMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);
  const [audioCall, setIsAudioCall] = useState(false);
  const [callingPlatfrom, setPlatform] = useState(null);
  const [appointmentNumber, setAppointment] = useState(null);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [logUpdated, setLogUpdated] = useState(false);
  const [callDuration, setCallDuration] = useState(0);

  const processor = useRef(null);
  const extension = useRef(new VirtualBackgroundExtension());

  const urlParams = new URLSearchParams(window.location.search);
  const senderEmail = urlParams.get("senderEmail");
  const mobileNumber = senderEmail.match(/\d{10,}/);
  const cname = urlParams.get("channel");
  const email = cname.replace(/\./g, "");
  const doctorId = urlParams.get("doctorId");
  const logId = urlParams.get("log_id");

  const formatDuration = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return (
      String(hrs).padStart(2, "0") +
      ":" +
      String(mins).padStart(2, "0") +
      ":" +
      String(secs).padStart(2, "0")
    );
  };
  const token = "f0924810ffd04733b7a726cb961157cd";
  // useEffect(() => {
  //   const fetchToken = async () => {
  //     try {
  //       const response = await fetch(
  //         `https://testingnew.webddocsystems.com/public/api/v1/agora/token?channel_name=${cname}`
  //       );
  //       const data = await response.json();
  //       if (data.statusCode === 1) {
  //         console.log("RTC token fetched from server: ", data.payLoad.token);
  //         setToken(data.payLoad.token);
  //       } else {
  //         throw new Error("Failed to fetch RTC token: " + data.statusMessage);
  //       }
  //     } catch (error) {
  //       console.error("Failed to fetch token:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchToken();
  // }, []);

  useEffect(() => {
    // if (!token) return;
    // Initialize AgoraRTC client
    const agoraClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    setClient(agoraClient); // Set the client in the state

    // Subscribe to remote user's audio track
    agoraClient.on("user-published", async (user, mediaType) => {
      await agoraClient.subscribe(user, mediaType);
      console.log("User subscribed:", user);
      if (mediaType === "video") {
        const remoteVideoTrack = user.videoTrack;
        remoteVideoTrack.play(remoteVideoRef.current);
      } else if (mediaType === "audio") {
        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack.play();
      }
    });

    const initializeVirtualBackgroundProcessor = async (localVideoTrack) => {
      AgoraRTC.registerExtensions([extension.current]);
      console.log("Initializing virtual background processor...");
      try {
        processor.current = extension.current.createProcessor();
        await processor.current.init("/path/to/your/wasm/file.wasm");

        localVideoTrack
          .pipe(processor.current)
          .pipe(localVideoTrack.processorDestination);

        // Create an Image object
        const image = new Image();
        image.onload = async () => {
          processor.current.setOptions({ type: "img", source: image });
          await processor.current.enable();
        };
        image.src = "/new-bg-pic.jpeg"; // Update the path to your image
      } catch (error) {
        console.error("Error initializing virtual background:", error);
      }
    };

    const joinChannel = async () => {
      try {
        const doctorCallRef = ref(database, `DoctorCall/${email}`);
        const snapshot = await get(doctorCallRef);
        const doctorData = snapshot.val();
        const platfrom = doctorData.CallingPlatform;
        const appointmentNumber = doctorData.AppointmentID;

        setPlatform(platfrom);
        setAppointment(appointmentNumber);
        if (doctorData && doctorData.CallType === "Incoming Audio Call") {
          try {
            await agoraClient.join(token, cname, null, null);
            setJoined(true);
            setIsAudioCall(true);
            console.log("Successfully joined the channel");
            const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
            await agoraClient.publish([localAudioTrack]);
          } catch (error) {
            console.error("Failed to join the channel:", error);
          }
        } else {
          try {
            await agoraClient.join(token, cname, null, null);
            setJoined(true);
            console.log("Successfully joined the channel");
            const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
            const localVideoTrack = await AgoraRTC.createCameraVideoTrack();
            localVideoTrack.play(localVideoRef.current);
            await agoraClient.publish([localAudioTrack, localVideoTrack]);
            await initializeVirtualBackgroundProcessor(localVideoTrack);
          } catch (error) {
            console.error("Failed to join the channel:", error);
          }
        }
      } catch (error) {
        console.error("Error fetching doctor call data:", error);
      }
    };

    const makeCallEntryForFirebase = async (email, calling, status) => {
      try {
        const callEntryData = {
          // AppointmentID: "0",
          IsCalling: calling,
          SenderEmail: senderEmail,
        };
        await update(ref(database, `DoctorCall/${email}`), callEntryData);

        const doctorStatusUpdate = {
          status: status,
        };
        await update(ref(database, `Doctors/${email}`), doctorStatusUpdate);
      } catch (error) {
        console.error("Error making call:", error);
        // Handle error
      }
    };
    makeCallEntryForFirebase(email, "", "busy");
    joinChannel();
    return () => {
      processor.current?.unpipe();
      agoraClient.leave();
    };
  }, [token]);

  const toggleAudioMute = () => {
    if (client) {
      const localAudioTrack = client.localTracks.find(
        (track) => track.trackMediaType === "audio"
      );
      if (localAudioTrack) {
        if (audioMuted) {
          localAudioTrack.setEnabled(true);
        } else {
          localAudioTrack.setEnabled(false);
        }
        setAudioMuted(!audioMuted);
      }
    }
  };

  const toggleVideoMute = () => {
    if (client) {
      const localVideoTrack = client.localTracks.find(
        (track) => track.trackMediaType === "video"
      );
      if (localVideoTrack) {
        if (videoMuted) {
          localVideoTrack.setEnabled(true);
        } else {
          localVideoTrack.setEnabled(false);
        }
        setVideoMuted(!videoMuted);
      }
    }
  };

  const videCallEnd = useCallback(async () => {
    setJoined(false);
    toggleVideoMute();
    if (client) {
      console.log("Before leaving call: ", client);
      client.leave();
      client.removeAllListeners();
    }

    if (processor.current) {
      processor.current.unpipe();
      await processor.current.disable();
    }

    if (client) {
      try {
        const callEntryData = {
          // AppointmentID: "0",
          CallType: "",
          IsCalling: "",
          SenderEmail: senderEmail,
        };
        await update(ref(database, `DoctorCall/${email}`), callEntryData);

        const doctorStatusUpdate = {
          status: "online",
        };
        await update(ref(database, `Doctors/${email}`), doctorStatusUpdate);
      } catch (error) {
        console.error("Error making call:", error);
        // Handle error
      }
    }
  }, [client, senderEmail]);

  const audioCallEnd = useCallback(async () => {
    setJoined(false);
    if (localAudioRef.current) {
      const localAudioTrack =
        localAudioRef.current.srcObject.getAudioTracks()[0];
      if (localAudioTrack) {
        localAudioTrack.stop();
      }
    }

    if (client) {
      console.log("Before leaving call: ", client);
      client.leave();
      client.removeAllListeners();
    }

    if (client) {
      try {
        const callEntryData = {
          // AppointmentID: "0",
          CallType: "",
          IsCalling: "",
          SenderEmail: senderEmail,
        };
        await update(ref(database, `DoctorCall/${email}`), callEntryData);

        const doctorStatusUpdate = {
          status: "online",
        };
        await update(ref(database, `Doctors/${email}`), doctorStatusUpdate);
      } catch (error) {
        console.error("Error making call:", error);
        // Handle error
      }
    }
  }, [client, senderEmail]);

  const handleEndCall = () => {
    toggleAudioMute();
    videCallEnd();
    updateLogOnLeaveCall(doctorId, patientId, "Ended by doctor", logId);
  };

  const handleEndAudioCall = () => {
    toggleAudioMute();
    audioCallEnd();
    updateLogOnLeaveCall(doctorId, patientId, "Ended by doctor", logId);
  };

  // if (client) {
  //   client.on("user-left", (user) => {
  //     console.log("User left:", user);
  //     toggleAudioMute();
  //     videCallEnd();

  //     updateLogOnLeaveCall(doctorId, patientId, "Ended by patient");
  //   });
  // }

  useEffect(() => {
    if (client) {
      const handleUserLeft = (user) => {
        console.log("User left:", user);
        toggleAudioMute();
        videCallEnd();
        if (!logUpdated) {
          updateLogOnLeaveCall(doctorId, patientId, "Ended by patient", logId);
          setLogUpdated(true);
        }
      };

      client.on("user-left", handleUserLeft);

      // Clean up the event listener on component unmount
      return () => {
        client.off("user-left", handleUserLeft);
      };
    }
  }, [client, doctorId, patientId, logUpdated]);

  const closeWindow = () => {
    // const redirectUrl = `https://portal.webdoc.com.pk/Doctor/SearchPatient`;
    const redirectUrl = `https://portal.webdoc.com.pk/Doctor/SearchPatient`;
    window.close();
    window.open(redirectUrl);
  };

  // ======= Video Calling Code End ===============

  const [formData, setFormData] = useState({
    Complaint: "",
    Diagnosis: "",
    Tests: [],
    Remarks: "",
    ConsultationType: audioCall ? "audio" : "VideoCall",
    Relation: "",
  });

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ConsultationType: audioCall ? "audio" : "VideoCall",
    }));
  }, [audioCall]);

  useEffect(() => {
    fetchTestsList();
    updateLog(doctorId, patientId, logId);
  }, [doctorId, patientId]);

  const fetchTestsList = async () => {
    try {
      const response = await fetch(
        "https://testingnew.webddocsystems.com/public/api/v1/webdoc/dropdowns/tests"
      );
      const data = await response.json();
      if (data.statusCode === 1) {
        setTestsList(
          data.payLoad.map((test) => ({ value: test.Name, label: test.Name }))
        );
      } else {
        console.error("Failed to fetch tests list");
      }
    } catch (error) {
      console.error("Error fetching tests list:", error);
    }
  };

  const updateLog = async (doctorId, patientId, logId) => {
    const staticData = {
      docotor_id: doctorId,
      patient_id: patientId,
      doctor_email: cname,
      patient_email: senderEmail,
      call_type: audioCall ? "Audio" : "Video",
      call_status: "Connected",
      platfrom: callingPlatfrom,
      log_definition: "Picked by doctor",
      remarks: logId,
    };
    try {
      const requestData = {
        ...staticData,
      };
      console.log(requestData);
      const response = await fetch("https://webdoc.com.pk/Jazz/save", {
        method: "POST",
        body: JSON.stringify(requestData),
      });
      const responseData = await response.json();
      if (response.ok && responseData.statusCode === 1) {
        console.log("Call logs saved successfully.");
      } else {
        console.log("Error in save call log.");
      }
    } catch (error) {
      console.error("Error in adding data:", error);
    }
  };

  const updateLogOnLeaveCall = async (doctorId, patientId, leave_by, logId) => {
    const staticData = {
      docotor_id: doctorId,
      patient_id: patientId,
      doctor_email: cname,
      patient_email: senderEmail,
      call_type: audioCall ? "Audio" : "Video",
      call_status: "Ended",
      platfrom: callingPlatfrom,
      log_definition: leave_by,
      remarks: logId,
    };
    try {
      const requestData = {
        ...staticData,
      };
      console.log(requestData);
      const response = await fetch("https://webdoc.com.pk/Jazz/save", {
        method: "POST",
        body: JSON.stringify(requestData),
      });
      const responseData = await response.json();
      if (response.ok && responseData.statusCode === 1) {
        console.log("Call logs updated successfully.");
      } else {
        console.log("Error in update call log.");
      }
    } catch (error) {
      console.error("Error in updating data:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // Clear error message when user starts typing in the field
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSelectChange = (selectedOptions) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      Tests: selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [],
    }));
  };
  const [tableData, setTableData] = useState([]);

  const handlePrescriptionSubmit = (prescriptionData) => {
    setTableData(prescriptionData);
  };

  useEffect(() => {
    let timerId;
    // Update call duration every second
    if (joined) {
      timerId = setInterval(() => {
        setCallDuration((prevDuration) => prevDuration + 1);
      }, 1000);
    }
    // Clear the interval when the component unmounts or call ends
    return () => {
      clearInterval(timerId);
    };
  }, [joined]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    const requiredFields = ["Complaint", "ConsultationType", "Relation"];
    const newErrors = {};
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field} is required`;
      }
    });
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    const staticData = {
      PrescriptionData: tableData,
      DoctorId: doctorId,
      DoctorEmail: cname,
      SenderEmail: senderEmail,
      PatientId: patientId,
      mobileNumber: mobileNumber,
      patientLocation: patientDetail.Address,
      patientcompany: patientDetail.patientcompany,
      appointmentNumber: appointmentNumber,
    };
    try {
      const requestData = {
        ...formData,
        ...staticData,
      };
      setSaving(true);
      let url;
      if (callingPlatfrom === "Afghan Telecom") {
        url =
          "https://testingnew.webddocsystems.com/public/api/v1/afghan/consultation/save";
      } else {
        url =
          "https://testingnew.webddocsystems.com/public/api/v1/webdoc/doctor/consultation/save";
      }
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(requestData),
      });
      const responseData = await response.json();
      if (response.ok && responseData.statusCode === 1) {
        // Show success message
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Prescription/Consultation sent to patient successfully!",
        }).then(() => {
          // Clear form fields
          setFormData({
            Complaint: "",
            Diagnosis: "",
            Tests: [],
            Remarks: "",
            ConsultationType: "audio",
            Relation: "",
          });
          // Clear table
          const updatedTableData = [...tableData];
          setTableData(updatedTableData);
        });
      } else {
        // Show error message
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to save consultation. Please contact to the adminstrator.",
        });
      }
    } catch (error) {
      console.error("Error saving data:", error);
      // Show error message
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An unexpected error occurred. Please contact the administrator.",
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-10 bg-light">
            <div
              className="d-flex justify-content-center"
              style={{
                backgroundColor: "#e82646",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                height: "50px",
              }}
            >
              <h5 className="text-white mt-2">
                Patient Details & Prescription
              </h5>
            </div>
            <div className="container">
              {callingPlatfrom === "Afghan Telecom" ? (
                <>
                  <AfnPatientDetails
                    appointmentNumber={appointmentNumber}
                    mobile={senderEmail}
                    handlePatientId={handlePatientId}
                    handlePatientDetail={handlePatientDetail}
                  />
                  <AfnPrescriptionForm
                    handlePrescriptionSubmit={handlePrescriptionSubmit}
                  />
                </>
              ) : (
                <>
                  <PatientDetails
                    mobile={senderEmail}
                    handlePatientId={handlePatientId}
                    handlePatientDetail={handlePatientDetail}
                  />
                  <PrescriptionForm
                    handlePrescriptionSubmit={handlePrescriptionSubmit}
                  />
                </>
              )}

              <form onSubmit={handleSubmit}>
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="Complaint">
                                <span>Complaints</span>{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.Complaint ? "is-invalid" : ""
                                }`}
                                name="Complaint"
                                id="Complaint"
                                placeholder=""
                                value={formData.Complaint}
                                onChange={handleInputChange}
                              />
                              {errors.Complaint && (
                                <div className="invalid-feedback">
                                  {errors.Complaint}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="Diagnosis">
                                <span>Diagnosis</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="Diagnosis"
                                id="Diagnosis"
                                placeholder=""
                                value={formData.Diagnosis}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="Tests">
                                <span>Tests</span>
                              </label>
                              <Select
                                name="Tests"
                                id="seletedTests"
                                className={`${
                                  errors.Tests ? "is-invalid" : ""
                                }`}
                                value={testsList.filter((test) =>
                                  formData.Tests.includes(test.value)
                                )}
                                onChange={handleSelectChange}
                                options={testsList}
                                isMulti
                                placeholder="Select Tests"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="Remarks">
                                <span>Advice</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="Remarks"
                                name="Remarks"
                                placeholder=""
                                value={formData.Remarks}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="Relation">
                                <span>Relation</span>{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                className={`form-control ${
                                  errors.Relation ? "is-invalid" : ""
                                }`}
                                id="Relation"
                                name="Relation"
                                value={formData.Relation}
                                onChange={handleInputChange}
                              >
                                <option value="">Select</option>
                                <option value="Self">Self</option>
                                <option value="wife">Wife</option>
                                <option value="mother">Mother</option>
                                <option value="daughter">Daughter</option>
                                <option value="father">Father</option>
                                <option value="son">Son</option>
                              </select>
                              {errors.Relation && (
                                <div className="invalid-feedback">
                                  {errors.Relation}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="ConsultationType">
                                <span>CallType</span>{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="ConsultationType"
                                  id="audioCall"
                                  value="audio"
                                  checked={
                                    formData.ConsultationType === "audio"
                                  }
                                  onChange={handleInputChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="audioCall"
                                >
                                  Audio
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="ConsultationType"
                                  id="videoCall"
                                  value="VideoCall"
                                  checked={
                                    formData.ConsultationType === "VideoCall"
                                  }
                                  onChange={handleInputChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="videoCall"
                                >
                                  Video
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="mt-2 d-flex justify-content-end align-items-end">
                              <input
                                type="submit"
                                value={saving ? "Saving..." : "Save"}
                                className="btn btn-info"
                                disabled={saving}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div
            className="col-12 col-md-2 py-3"
            style={{ backgroundColor: "#ccc" }}
          >
            <div className="container">
              <div className="patient-detail-section">
                <div className="d-flex justify-content-center">
                  <p style={{ fontSize: "15px" }} className="section-title">
                    Calling From
                  </p>
                </div>
                <div className="d-flex justify-content-center">
                  <span
                    className="section-content text-primary"
                    style={{ fontSize: "15px" }}
                  >
                    {callingPlatfrom ? callingPlatfrom : "WebDoc"}
                  </span>
                </div>
              </div>
              <>
                {audioCall ? (
                  <div>
                    <div className="patient-detail-section">
                      <div className="d-flex justify-content-center">
                        <h5 className="text-info">Audio Call</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col d-flex justify-content-center mb-2">
                        <p style={{ fontSize: "13px", marginBottom: "5px" }}>
                          {formatDuration(callDuration)}
                        </p>
                      </div>
                    </div>
                    <img
                      src="/voice-call.png"
                      alt="Audio Call"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                ) : (
                  <>
                    {!joined ? (
                      <div></div>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col d-flex justify-content-center">
                            <p
                              style={{ fontSize: "13px", marginBottom: "5px" }}
                            >
                              {formatDuration(callDuration)}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <video
                              ref={remoteVideoRef}
                              autoPlay
                              playsInline
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "2px solid #1e88e5",
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <video
                              ref={localVideoRef}
                              autoPlay
                              playsInline
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "2px solid #1e88e5",
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>

              <div className="d-flex justify-content-center mt-3">
                {!joined ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={closeWindow}
                    >
                      Close this screen
                    </button>
                  </>
                ) : (
                  <>
                    {audioCall ? (
                      <>
                        <button
                          className="btn btn-primary me-2"
                          onClick={toggleAudioMute}
                        >
                          <FontAwesomeIcon
                            icon={audioMuted ? faMicrophoneSlash : faMicrophone}
                          />
                        </button>
                        <button
                          className="btn btn-danger me-2"
                          onClick={handleEndAudioCall}
                        >
                          <FontAwesomeIcon icon={faPhone} />
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn btn-primary me-2"
                          onClick={toggleAudioMute}
                        >
                          <FontAwesomeIcon
                            icon={audioMuted ? faMicrophoneSlash : faMicrophone}
                          />
                        </button>
                        <button
                          className="btn btn-danger me-2"
                          onClick={handleEndCall}
                        >
                          <FontAwesomeIcon icon={faPhone} />
                        </button>
                        <button
                          className="btn btn-success"
                          onClick={toggleVideoMute}
                        >
                          <FontAwesomeIcon
                            icon={videoMuted ? faVideoSlash : faVideo}
                          />
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Incoming;
