import React, { useState, useEffect } from "react";
import "./patient-detail.css";

const PatientDetails = ({ mobile, handlePatientId, handlePatientDetail }) => {
  const [patientDetailsModalVisible, setPatientDetailsModalVisible] =
    useState(false);
  const [patientDetail, setPatientDetail] = useState([]);
  const [consultationDetail, setConsultationDetail] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPatientDetail();
  }, []);

  const fetchPatientDetail = async () => {
    try {
      const response = await fetch(
        `https://testingnew.webddocsystems.com/public/api/v1/webdoc/patient/profile?phone=${mobile}`
      );
      const data = await response.json();
      if (data.statusCode === 1) {
        setPatientDetail(data.payLoad);
        setConsultationDetail(data.payLoad.consultation);
        handlePatientId(data.payLoad.ApplicationUserId);
        handlePatientDetail(data.payLoad);
      } else {
        console.error("Failed to fetch patient detail");
      }
    } catch (error) {
      console.error("Error fetching patient detail:", error);
    } finally {
      setLoading(false);
    }
  };



  const openPatientDetailsModal = () => {
    setPatientDetailsModalVisible(true);
  };

  const closePatientDetailsModal = () => {
    setPatientDetailsModalVisible(false);
  };
  return (
    <>
      <div className="card p-3 mt-2">
        <div className="row">
          <>
            {loading && (
              <div className="loader-container">
                <div className="loading-text">Loading...</div>
                <div className="loader-bar"></div>
              </div>
            )}
            <div className="col-4">
              <p className="mb-0">Name: {patientDetail.username}</p>
              <p className="mb-0">Mobile: {patientDetail.MobileNumber}</p>
            </div>
            <div className="col-5">
              <p className="mb-0">Gender: {patientDetail.Gender}</p>
              <p className="mb-0">Age: {patientDetail.Age}</p>
            </div>
            <div className="col-3 d-flex justify-content-end align-items-center ">
              <button
                type="button"
                className="btn btn-info"
                onClick={openPatientDetailsModal}
              >
                See Patient Detail
              </button>
            </div>
          </>
        </div>
      </div>

      {/* Patient Details Modal */}
      <div
        className={`modal ${patientDetailsModalVisible ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: patientDetailsModalVisible ? "block" : "none" }}
      >
        {/* Modal content */}
        <div className="modal-dialog" role="document">
          <div className="modal-content" style={{ backgroundColor: "azure" }}>
            <div
              className="modal-header"
              style={{ backgroundColor: "#7be3e3" }}
            >
              <h5 className="modal-title">Patient Details</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closePatientDetailsModal}
              ></button>
            </div>
            <div className="modal-body">
              <div className="patient-details">
                <div className="row">
                  <div className="col-md-6">
                    <p className="card-text">
                      <strong>Patient Name:</strong> {patientDetail.FirstName}{" "}
                      {patientDetail.LastName}
                    </p>
                    <p className="card-text">
                      <strong>Mobile:</strong> {patientDetail.MobileNumber}
                    </p>
                    <p className="card-text">
                      <strong>Gender:</strong> {patientDetail.Gender}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p className="card-text">
                      <strong>Address:</strong> {patientDetail.Address}
                    </p>
                    <p className="card-text">
                      <strong>Age:</strong> {patientDetail.Age}
                    </p>
                    <p className="card-text">
                      <strong>City:</strong> {patientDetail.City}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {consultationDetail &&
              consultationDetail.map((value) => (
                <div className="card mb-4 m-2" key={value.Id}>
                  <div
                    className="card-header p-3"
                    style={{ backgroundColor: "rgb(78 85 225)" }}
                  >
                    <span className="card-title text-white">
                      {value.DoctorFullName &&
                        value.DoctorFullName.charAt(0).toUpperCase() +
                          value.DoctorFullName.slice(1)}
                    </span>
                    <small className="text-white">
                      {" "}
                      ({value.ConsultationDate})
                    </small>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <p className="card-text">
                          <strong>Complaint:</strong> {value.Complaint}
                        </p>
                        <p className="card-text">
                          <strong>Diagnosis:</strong> {value.Diagnosis}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="card-text">
                          <strong>Tests:</strong> {value.Tests}
                        </p>
                        <p className="card-text">
                          <strong>Advice:</strong> {value.Remarks}
                        </p>
                      </div>
                    </div>
                    {value.Consultationdetails &&
                      value.Consultationdetails.map((innerValue) => (
                        <ul className="list-group mt-2" key={innerValue.Id}>
                          <li className="list-group-item">
                            <strong>Medicine:</strong> {innerValue.MedicineName}
                            <br />
                            <strong>Days:</strong> {innerValue.Days}
                            <small>
                              {" "}
                              ({innerValue.Morning} + {innerValue.Day} +{" "}
                              {innerValue.Night})
                            </small>
                            <br />
                            <strong>Info:</strong> {innerValue.AdditionalNotes}
                            din tak.
                          </li>
                        </ul>
                      ))}
                  </div>
                </div>
              ))}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closePatientDetailsModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientDetails;
