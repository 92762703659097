import React, { useEffect } from "react";
import {
  Routes,
  Route,
} from "react-router-dom";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


import AuthenticationWorkflow from "./authentication-workflow/authenticationWorkflow";
import Incoming from "./pages/incoming";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <Routes>
        <Route path="/" element={<AuthenticationWorkflow />} />
        <Route path="/incoming" element={<Incoming />} />
      </Routes>
  );
}

export default App;
